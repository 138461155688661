import React from "react"

export default function Pattern({ size = 60, className = "", style = {} }) {
  return (
    <div
      className={className}
      style={{
        ...style,
        backgroundSize: `${size}px ${size}px`,
        backgroundImage: `url('${
          require("../../images/pattern-1.svg").default
        }')`,
      }}
    />
  )
}

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as R from "ramda"

import { FAQ } from "../components/Website/Faqs"
import { H1, H2 } from "../components/Typography"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Pattern from "../components/Layout/Pattern"

const About = () => {
  const {
    allContentfulInstructors: instructors,
    allContentfulAbout: content,
  } = useStaticQuery(graphql`
    query AllAboutContent {
      allContentfulAbout {
        nodes {
          mission {
            mission
          }
          vision {
            vision
          }
          whyCmk {
            whyCmk
          }
        }
      }

      allContentfulInstructors(sort: { order: ASC, fields: title }) {
        edges {
          node {
            id
            fullname
            order
            title
            image {
              file {
                details {
                  image {
                    height
                    width
                  }
                }
                url
              }
            }
            shortBio {
              shortBio
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="About" />
      <section className="container mx-auto pt-12 md:pt-24 flex flex-col md:flex-row items-start">
        <div className="px-4 flex-1">
          <H1 className="mb-4 md:mb-8">
            About CMK <br /> Culinary
          </H1>
          <p className="text-base md:text-xl max-w-lg font-thin opacity-50">
            {content.nodes[0].mission?.mission}
          </p>
        </div>

        <div
          className="px-4 md:px-0 flex-1
            overflow-x-hidden lg:overflow-x-visible
            w-full grid grid-cols-3 grid-rows-6 gap-2 md:gap-8 transform translate-y-24
            min-h-300 lg:min-h-512"
        >
          <Pattern
            className="h-64 w-full right-0 top-0 transform 
                -translate-y-16 translate-x-16 bg-contain absolute z-10"
            style={{
              opacity: 0.55,
            }}
          />
          <div
            className="overflow-hidden relative z-20 bg-no-repeat bg-cover rounded-xl bg-gray-200  row-span-2"
            style={{
              backgroundImage: `url(${
                require("../images/pancakes.jpg").default
              })`,
            }}
          />
          <div
            className="overflow-hidden relative z-20 bg-no-repeat bg-cover rounded-xl  bg-gray-200  col-span-2 row-span-3"
            style={{
              backgroundImage: `url(${
                require("../images/students-1.jpg").default
              })`,
            }}
          />
          <div
            className="overflow-hidden relative z-20 bg-no-repeat bg-cover rounded-xl  bg-gray-200  row-span-4"
            style={{
              backgroundImage: `url(${
                require("../images/pastry-1.jpg").default
              })`,
            }}
          />
          <div
            className="overflow-hidden relative z-20 bg-no-repeat bg-cover rounded-xl  bg-gray-200  col-span-2 row-span-3"
            style={{
              backgroundImage: `url(${
                require("../images/students-2.jpg").default
              })`,
            }}
          />
        </div>
      </section>
      <section className="py-24 px-4 md:px-0 mt-12 h-64 bg-opacity-25 bg-_2"></section>
      <section className="flex flex-col overflow-hidden lg:overflow-visible lg:flex-row py-24 justify-end container mx-auto">
        <div
          className="relative w-full lg:w-3/6 flex-shrink-0 flex-1 max-w-lg grid grid-rows-4 grid-cols-3 gap-2"
          style={{ height: 600 }}
        >
          <div className="absolute z-10 flex justify-center items-center inset-0">
            <div className="w-64 h-64 bg-primary bg-opacity-25 rounded-full" />
          </div>
          <figure
            className="col-span-2 
                shadow-xl transform -rotate-12 overflow-hidden relative z-20 bg-no-repeat bg-cover rounded-xl bg-gray-200  row-span-2"
            style={{
              backgroundImage: `url(${
                require("../images/pastry-3.jpg").default
              })`,
            }}
          ></figure>
          <div className="row-span-2"></div>
          <div className="row-span-2"></div>
          <figure
            className="col-span-2 transform rotate-12 overflow-hidden relative z-0 bg-no-repeat bg-cover rounded-xl bg-gray-200  row-span-2"
            style={{
              backgroundImage: `url(${
                require("../images/pastry-2.jpg").default
              })`,
            }}
          />
        </div>
        <div className="w-full lg:w-3/6 py-12 px-8 md:px-12 select-none">
          <H2>What we do?</H2>
          <p className="text-lg max-w-md mt-8 opacity-75">
            {content.nodes[0].whyCmk?.whyCmk}
          </p>
        </div>
      </section>

      <section className="container mx-auto">
        <div className="w-full lg:w-11/12 px-8 md:px-0 mr-0 ml-auto select-none">
          <H2>Vision</H2>
          <p className="text-lg max-w-md mt-8 opacity-75">
            {content.nodes[0].vision?.vision}
          </p>
        </div>
      </section>

      <section className="flex flex-col py-24 container mx-auto select-none">
        <div className="w-11/12 ml-auto mr-0">
          <H2>Meet your Instructors</H2>
        </div>
        <div className="w-10/12 ml-auto mr-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mt-12">
          {instructors.edges
            .sort((a, b) => a?.node?.order > b?.node?.order)
            .map(({ node }) => (
              <div key={node.id} className="flex flex-col">
                <figure
                  className="w-full h-64 bg-gray-200"
                  style={{
                    backgroundImage: `url(${node?.image?.file?.url})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
                <section className="p-6">
                  <h4 className="text-xl font-display font-bold">
                    {node.fullname}
                  </h4>
                  <p className="uppercase text-sm font-body">{node.title}</p>
                  <p className="mt-4 opacity-75 text-sm font-body">
                    {node.shortBio?.shortBio}
                  </p>
                </section>
              </div>
            ))}
        </div>
      </section>
      <FAQ />
    </Layout>
  )
}

export default About

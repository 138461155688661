import React from "react"
import t from "prop-types"
import { mergeClass } from "../style_helpers"

export const H1 = ({ children, className = "", ...props }) => {
  return (
    <h1
      {...props}
      className={mergeClass(
        "text-2xl lg:text-4xl xl:text-7xl flex flex-col font-bold text-_1 font-display",
        className
      )}
    >
      {children}
    </h1>
  )
}

export const H2 = ({ children, className = "", ...props }) => {
  return (
    <h1
      {...props}
      className={mergeClass(
        "text-2xl lg:text-5xl flex flex-col font-bold tracking-tight text-_1 font-display leading-tight",
        className
      )}
    >
      {children}
    </h1>
  )
}

export const H3 = ({ children, className = "", ...props }) => {
  return (
    <h1
      {...props}
      className={mergeClass(
        "text-xl lg:text-2xl flex flex-col font-bold tracking-tight text-_1 font-display leading-tight",
        className
      )}
    >
      {children}
    </h1>
  )
}

export const H4 = ({ children, className = "", ...props }) => {
  return (
    <h1
      {...props}
      className={mergeClass(
        "lg:text-lg flex flex-col font-bold tracking-tight text-_1 font-display",
        className
      )}
    >
      {children}
    </h1>
  )
}

export const P = ({ children, className = "", ...props }) => {
  return (
    <p {...props} className={mergeClass("text-normal font-display", className)}>
      {children}
    </p>
  )
}

P.defaultProps = {
  className: "",
}

H1.propTypes = H2.propTypes = H3.propTypes = H4.propTypes = P.propTypes = {
  children: t.node.isRequired,
  className: t.string,
}
